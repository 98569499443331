@import "../variablesFull";
@import "../../modules/mod-sticky-component";

@include mod-sticky-component();

/*Desktop viewport - > 1440*/
@media all and (min-width: $viewport-width-desktop + px) {
    // @include mod-sticky-component();
}

/*Tablet Landscape viewport - 990 / 1439*/
@media all and (min-width: $viewport-width-tablet-portrait + px) and (max-width: ($viewport-width-desktop - 1) + px) {
    @include mod-sticky-component-tablet-landscape();
}

/*Tablet Portrait viewport - 768 / 989*/
@media all and (min-width: $viewport-width-mobile + px) and (max-width: ($viewport-width-tablet-portrait - 1) + px) {
    @include mod-sticky-component-tablet-portrait();
}

/*Mobile viewport - 767*/
@media all and (max-width: ($viewport-width-mobile - 1) + px) {
    @include mod-sticky-component-mobile();
}
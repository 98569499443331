@mixin mod-sticky-component(){
    .sticky-component{
        .modale-simple, .modal-generali{
            .title{
                font-size: 18px;
                font-weight: bold;
                padding-top:32px;
            }
            &.show{
                display: flex !important;
            }
            .modal-xl{
                max-width: 940px;
                width: auto;
                margin: auto;
            }
            .modal-dialog{
                .modal-content{
                    border-radius:12px;
                    .modal-header{
                        padding: 0;
                        border-bottom: unset;
                        .close{
                            margin: 0;
                            padding: 0;
                            opacity:1;
                            .close-text{
                                padding-bottom: 2px;
                                padding-left: 3px;
                            }
                        }
                        .icon-close{
                            color: white;
                            font-size: 27px !important;
                            font-weight:300;
                            background-color:#ec6608;
                            position: absolute;
                            border-radius: 100px;
                            padding:8px;
                            right: -20px;
                            top: -20px;
                            width:42px;
                            height:42px;
                        }
                    }
                    .modal-body{
                        min-height:551px;
                        padding: 0 45px;
                        &-bottom{
                            height: 187px;
                        }
                        .play-text{
                            font-size: 18px;
                        }
                        .modal-line-divider{
                            width: 100%;
                            height: 1px;
                            background-color: #bababa;
                        }
                        .btn-generali{
                            padding: 18px;
                            width: 100%;
                            max-width: 282px;
                        }
                    }
                }
            }
            .icon-star{
                width: 120px;
                height: 122px;
            }
        }
        .sticky-container{
            position: fixed;
            bottom: 27px;
            z-index: 1000;
            width: 100%;
            display: flex;
            .sticky{
                width: 100%;
                height: auto;
                @include rem-fallback(padding-top, 13);
                @include rem-fallback(padding-bottom, 13);
                border-radius: 30px;
                box-shadow: 0 10px 59px 0 rgba(0, 0, 0, 0.15);
                background-color: #fff;
            }
            .show-desktop{
                display: unset!important;
            }
            .show-mobile{
                display: none !important;
            }
            .icon-star{
                width: 67px;
                height: 68px;
            }
            .btn-generali{
                padding: 8px;
                max-width: 180px;
                position: relative;
                height: auto;
                display: flex;
                min-height: 32px;
                padding: 8px;
                &:hover, &:focus{
                    color: $white;
		            background-color: $theme-color;
                    border-color: $theme-color;
                }
                span{
                    margin: auto;
                    overflow-wrap: anywhere;
                }
                +.btn-generali--outline{
                    @include rem-fallback(margin-left, 25);

                }
            }
        }
    }

}

@mixin mod-sticky-component-tablet-landscape(){
    .sticky-component{
        .modale-simple, .modal-generali{
            .title{
                font-size: 18px;
                font-weight: bold;
                padding-top:32px;
            }
            &.show{
                display: flex !important;
            }
            .modal-xl{
                max-width: 940px;
                width: auto;
                margin: auto;
            }
            .modal-dialog{
                .modal-content{
                    border-radius:12px;
                    .modal-header{
                        padding: 0;
                        border-bottom: unset;
                        .close{
                            margin: 0;
                            padding: 0;
                            opacity:1;
                            .close-text{
                                padding-bottom: 2px;
                                padding-left: 3px;
                            }
                        }
                    }
                    .modal-body{
                        min-height:551px;
                        padding: 0 45px;
                        &-bottom{
                            height: 187px;
                        }
                        .play-text{
                            font-size: 18px;
                        }
                        .modal-line-divider{
                            width: 100%;
                            height: 1px;
                            background-color: #bababa;
                        }
                        .btn-generali{
                            padding: 18px;
                            width: 100%;
                            max-width: 282px;
                        }
                    }
                }
            }
            .icon-star{
                width: 120px;
                height: 122px;
            }
        }
        .sticky-container{
            position: fixed;
            bottom: 27px;
            z-index: 1000;
            width: 100%;
            display: flex;
            .sticky{
                width: 100%;
                height: auto;
                @include rem-fallback(padding-top, 13);
                @include rem-fallback(padding-bottom, 13);
                border-radius: 30px;
                box-shadow: 0 10px 59px 0 rgba(0, 0, 0, 0.15);
                background-color: #fff;
            }
            .show-desktop{
                display: unset!important;
            }
            .show-mobile{
                display: none !important;
            }
            .icon-star{
                width: 67px;
                height: 68px;
            }
            .btn-generali{
                padding: 8px;
                max-width: 180px;
                position: relative;
                height: auto;
                display: flex;
                min-height: 32px;
                padding: 8px;
                &:hover, &:focus{
                    color: $white;
		            background-color: $theme-color;
                    border-color: $theme-color;
                }
                span{
                    margin: auto;
                    overflow-wrap: anywhere;
                }
                +.btn-generali--outline{
                    @include rem-fallback(margin-left, 25);

                }
            }
        }
    }
}

@mixin mod-sticky-component-tablet-portrait(){
    .sticky-component{
        .modale-simple, .modal-generali{
            .modal-xl{
                margin: auto 67px;
            }
            .modal-dialog{
                .modal-content{
                    .modal-body{
                        padding-left: 29px;
                        padding-right: 29px;
                        padding-top: 0;
                        .play-text{
                            font-size: 20px;
                        }
                    }
                }
            }
            .icon-star{
                width: 117.5px;
                height: auto;
            }
        }
        .small-container{
            @include rem-fallback(padding-right, 0);
            @include rem-fallback(padding-left, 0);
        }
        .sticky-container{
            bottom: 0;
            .sticky{
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                @include rem-fallback(padding-top, 15);
                @include rem-fallback(padding-bottom, 15);
                @include rem-fallback(padding-left, 0);
            }
            .icon-star{
                width: 48px;
                height: 49px;
            }
            .sticky-description{
                width:90%;
            }
            .btn-generali{
                padding: 8px;
                max-width: 138px;
                +.btn-generali--outline{
                    @include rem-fallback(margin-left, 12);

                }
            }
        }
    }

}

@mixin mod-sticky-component-mobile(){
    .sticky-component{
        .modale-simple, .modal-generali{
            .modal-xl{
                width: auto;
                max-width: 100%;
                margin: auto 44px;
            }
            .modal-dialog{
                .modal-content{
                    .modal-header{
                        padding: 0;
                        border-bottom: unset;
                        .close{
                            margin: 0;
                            padding: 0;
                            opacity:1;
                            i{
                                font-size: 20px;
                            }
                        }
                    }
                    .modal-body{
                        min-height:550px;
                        &-bottom{
                            height: 131px;
                        }
                        padding-left: 29px;
                        padding-right: 9px;
                        padding-top: 0;
                        .scroll-mobile-box{
                            max-height: 315px;
                            padding-right: 30px;
                            overflow-y: scroll;
                        }
                        .play-text{
                            font-size: 14px;
                        }
                        .btn-generali{
                            max-width: 198px;
                            padding: 12px 8px;
                        }
                    }
                }
            }
            .icon-star{
                max-width: 90px;
                width: 100%;
                height: auto;
            }
        }
        .small-container{
            @include rem-fallback(padding-right, 0);
            @include rem-fallback(padding-left, 0);
        }
        .sticky-container{
            bottom: 0;
            .sticky{
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                @include rem-fallback(padding-top, 9);
                @include rem-fallback(padding-bottom, 15);
            }
            .padding-mobile{
                padding-left:5px;
                padding-right:5px;
            }
            .show-desktop{
                display: none !important;
            }
            .show-mobile{
                display: unset !important;
            }
            .icon-star{
                width: 53px;
                height: 54px;
            }
            .btn-generali{
                padding: 10px;
                max-width: unset;
                +.btn-generali--outline{
                    @include rem-fallback(margin-left, 6);
                    @include rem-fallback(margin-top, 0);

                }
            }
        }
    }
}
// Viewport settings
$viewport-width-desktop: 1440;
$viewport-width-tablet-landscape: 1440;
$viewport-width-tablet-portrait: 990;
$viewport-width-mobile: 768;
$viewport-width-mobile-small: 375;
$viewport-width-tablet-landscape-large: 1024;

// Fonts
$root-font-size: 18px;
$root-font-size-mobile: 14;
$root-font-size-sm: $root-font-size - 2;
$root-font-size-lg: $root-font-size + 3;
$font-serif: 'AlleanzaSerif';
$font-san-serif: Arial, $font-serif;
$font-primary: 'AlleanzaSans', 'Arial', sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

// Sizes
$app-max-width: 1440px;
$app-inner-container-max-width: 1200px;
$button-padding-x: 25px;
$button-padding-y: 18px;
$button-padding-x-mobile: 15px;
$button-padding-y-mobile: 10px;
$button-hero-padding-x: 30px;
$button-hero-padding-y: 20px;
$button-border: 2px;
$inp-padding-x: 12px;
$inp-padding-y: 9.5px;
$header-item-horizontal-padding: 13px;
$header-item-horizontal-padding-mobile: 20px;
$header-item-vertical-padding: 13px;
$header-item-icon-gutter: 16px;
$side-padding-d: 25px;
$side-padding-t: 25px;
$side-padding-m: 15px;

// Libs overwrites
// Bootstrap
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 990px,
  xl: 1440px
) !default;

$container-max-widths: (
  sm: 767px,
  md: 989px,
  lg: 1200px,
  // xl: 1200px
) !default;

// Misc
$transition-timing-function: ease;
$transition-duration: 250ms;

// Colors
$total-black: #000000;
$black: #000F20;
$black-2:  #000020;
$black-3: #20252b;
$black-4: #00002C;
$dark-grey-1: #303A45;
$dark-grey-2: #6E757C;
$light-grey-1: #999999;
$light-grey-2: #CACACA;
$light-grey-3: #d8d8d8;
$white: #FFFFFF;
$gradient-white: #f2f2f2;
$bg-grey: #F4F4F4;


// Colori Alleanza
$color-alleanza-orange: #EC6608; //#FC4C02;
$color-alleanza-blue: #002855;

// Link
$link-orange: #FF9741;
$link-blue-visited: #395082;
$button-orange-hover: #B23600;
$theme-color--text: $color-alleanza-blue;
$color-bottom-footer: rgba(255, 255, 255, 0.1);
$color-bottom-hr: rgba(255, 255, 255, 0.4);

// Categorie
$color-category-risparmio: #82D4EC;
$color-category-investimento: #3D9BC7;
$color-category-protezione: #FFAB00;
$color-category-previdenza: #FFD83C; 

$color-names-orange: #F07D07;

// Palette
$orange:#FF8200; // orange-peel
$dark-orange: #DC4405; // trinidad
$blue-chiaro: #00629B; // bahama
$dark-blue: #004B87; // congress blue

//- Scala di blu
$blue-light: #48A9C5 !default;
$blue: #298FC2 !default;
$blue-medium: #00629B !default;
$blue-alleanza: #004B87 !default;
//- Scala di giallo
$yellow: #FFC72C !default;
$orange-light: #FFA400 !default;
$orange: #FF8200 !default;


$banner-yellow: #FFC72C;

$color-feedback-success: #71CC2C;
$color-feedback-error: #DF2316;
$color-feedback-warning: #f0c90a;

$theme-color: $color-alleanza-blue;
$theme-color-light-blue: $color-category-investimento;

// Shadows
$shadow: 5px 5px 20px 0 rgba(0, 15, 32, 0.1);
$shadow-style: 0 0 40px 0 rgba(0, 15, 32, 0.16);
$shadow-hover: 3px 3px 8px 4px rgba(0, 15, 32, 0.1);
$shadow-hover-dark: 3px 3px 8px 5px rgba(0, 15, 32, 0.4);
$shadow-author: $shadow-style;
$shadow-input: 0 4px 8px 0 rgba(78, 78, 78, 0.2);
$shadow-tooltip: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
$card-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
$card-active-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$shadow-sticky-mobile: 0 -6px 20px 0 rgba(0, 15, 32, 0.16);



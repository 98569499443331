//Mixin for REM fallback
@function strip-unit($num) {
  @if $num == 'phone-text' {
    @return $num;
  } @else {
    @return $num / ($num * 0 + 1);
  }
}

@mixin rem-fallback($property, $values...) {
  $max: length($values);
  $pxValues: "";
  $remValues: "";
  $viewPort:"";
  $value:"";
  $phoneFlag: false;

  @for $i from 1 through $max {
    $pxTempValue: strip-unit(nth($values, $i));
    @if $pxTempValue == 'phone-text' {
      $viewPort : $pxTempValue;
      $phoneFlag: true;
    }
    @else {
      $value : $pxTempValue;
    }

    @if $viewPort == 'phone-text' {
      $pxValues: "";
      $pxValues: #{$pxValues + $value}px;
    }
    @else {
      $pxValues: #{$pxValues + $value}px;
    }


    @if $i < $max {
      $pxValues: #{$pxValues + " "};
    }
  }

  @for $i from 1 through $max {
    $remTempValue: strip-unit(nth($values, $i));
    @if $remTempValue == 'phone-text' {
      $viewPort : $remTempValue;
    }
    @else {
      $value : $remTempValue;
    }
    
    @if $viewPort == 'phone-text' {
      $remValues: "";
    }
    @else {
      $remValues: #{$remValues + $value / $root-font-size}rem;
    }
    
    @if $i < $max {
      $remValues: #{$remValues + " "};
    }
  }

  @if $phoneFlag {
    #{$property}: $pxValues;
    // #{$property}: $remValues;
  }
  @else {
    #{$property}: $pxValues;
    #{$property}: $remValues;
  }
}
